import {Component, HostListener, OnDestroy, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {MenuItem, PrimeNGConfig} from "primeng/api";
import {SidebarService} from "src/app/services/sidebar/sidebar.service";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {environment} from "src/environments/environment";
import {IdentityService} from "src/app/services/identity/identity.service";
import {IdentityUser} from "src/app/models/entities/identity-user";
import {Subject} from "rxjs";
import {catchError, takeUntil} from "rxjs/operators";
import {SpinnerService} from "src/app/services/spinner/spinner.service";
import {PersonalSettingsService} from "src/app/modules/shared/services/personal-settings.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit, OnDestroy {
  langList: MenuItem[] = [];
  userMenuList!: MenuItem[];
  user: IdentityUser | null = null;
  private unsubscriber = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private config: PrimeNGConfig,
    private sidebarService: SidebarService,
    private authService: OidcSecurityService,
    private identityService: IdentityService,
    private spinner: SpinnerService,
    private settings: PersonalSettingsService,
  ) {
  }

  async checkMenuOpen() {

    let isMenuOpen = await this.settings.getSetting('isMenuOpen');
    if (isMenuOpen === null || isMenuOpen === undefined) {
      isMenuOpen = true;
    }
    if (isMenuOpen && window.innerWidth <= 1400) {
      isMenuOpen = false;
    }

    this.sidebarService.setIsMenuOpen(isMenuOpen);
  }

  async ngOnInit() {
    this.initMenuLists();
    await this.loadLang();
    this.identityService.$identityUser.pipe(
      takeUntil(this.unsubscriber),
      catchError(e => {
        throw e;
      }))
      .subscribe(x => {
        this.user = x;
        if (x) {
          this.initUserMenuList();
        }
        this.spinner.hide();
      });
    await this.checkMenuOpen();
  }

  @HostListener('window:resize', ['$event'])
  async onResize() {
    await this.checkMenuOpen();
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  initUserMenuList(): void {
    if (!this.authService.isAuthenticated()) {
      return;
    }
    this.sidebarService.setMenu();

    this.userMenuList = [
      {
        label: `${this.user?.Firstname} ${this.user?.Lastname}`,
        disabled: true
      },
      {
        separator: true
      },
      {
        label: this.translateService.instant("header.change_password"),
        command: () => {
          window.open(environment.auth.authority + '/account/editlogin', "_blank");
        },
        icon: "pi pi-key"
      },
      {
        label: this.translateService.instant("header.settings"),
        command: () => {
          window.open(environment.auth.authority + '/account/edit', "_blank");
        },
        icon: "pi pi-cog"
      },
      {
        label: this.translateService.instant("header.logout"),
        command: () => {
          this.authService.logoff().subscribe(() => {
            console.log('logout')
          });
        },
        icon: "pi pi-sign-out"
      }
    ];
  }

  initMenuLists(): void {
    this.langList = [
      {
        label: "English", command: () => {
          this.translate("en");
          this.setLang("en");
        }
      },
      {
        label: "Deutsch", command: () => {
          this.translate("de");
          this.setLang("de");
        }
      }
    ];

    this.initUserMenuList();
  }

  toggleMenu(): void {
    this.sidebarService.setIsMenuOpen(!this.sidebarService.isSidebarOpen);
  }

  translate(lang: string) {
    this.translateService.use(lang);
    this.translateService.get("primeng").subscribe(res => this.config.setTranslation(res));
  }

  async loadLang() {
    let lang = await this.settings.getSetting(`lang`);
    if (!lang) {
      lang = "en";
      this.setLang(lang);
    }
    this.translate(lang);
  }

  setLang(lang: string) {
    this.settings.setSetting(`lang`, lang);
  }

}
