<header>
  <div class="header">
    <div class="logo-button-wrapper">
      <p-button type="button" icon="pi pi-bars" class="btn icon-btn p-button-text" (click)="toggleMenu()"></p-button>
    </div>
    <div class="logo">
      <img src="/assets/images/Area49_Logo.svg" alt="logo">
    </div>
    <!--    <div class="app-name">-->
    <!--      <h1>{{ 'header.header' | translate }}</h1>-->
    <!--    </div>-->
  </div>
  <div class="header-menu logo-button-wrapper">
    <app-nfc-player></app-nfc-player>
    <div class="logo-button-wrapper">
      <p-button type="button" icon="pi pi-globe" class="btn icon-btn p-button-text"
                (click)="langMenu.toggle($event)"></p-button>
      <p-menu #langMenu [popup]="true" [model]="langList" class="lang-menu"></p-menu>
    </div>
    <div class="logo-button-wrapper">
      <p-button type="button" icon="pi pi-user" class="btn icon-btn p-button-text"
                (click)="userMenu.toggle($event)"></p-button>
      <p-menu #userMenu [popup]="true" [model]="userMenuList" class="user-menu"></p-menu>
    </div>
  </div>
</header>
