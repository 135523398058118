import {Component, OnDestroy} from "@angular/core";
import {MenuItem} from "primeng/api";
import {SidebarService} from "src/app/services/sidebar/sidebar.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnDestroy {

  menuItems: MenuItem[] = [];
  private unsubscriber = new Subject<void>();

  constructor(
    private sidebar: SidebarService
  ) {
    this.sidebar.menuItems$.pipe(
      takeUntil(this.unsubscriber)
    ).subscribe(value => {
      this.menuItems = value;
    });
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

}
