import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from "src/app/components/error/not-found/not-found.component";
import {AuthGuard} from "src/app/modules/shared/guards/auth.guard";

const routes: Routes = [
  {path: '404', component: NotFoundComponent},
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    title: 'Dashboard | Area49',
    loadChildren: () => import('src/app/modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'game-day',
    title: 'Game day | Area49',
    loadChildren: () => import('src/app/modules/active-gameday/active-gameday.module').then(m => m.ActiveGamedayModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'equipment',
    title: 'Equipment | Area49',
    loadChildren: () => import('src/app/modules/equipment/equipment.module').then(m => m.EquipmentModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    title: 'Admin | Area49',
    loadChildren: () => import('src/app/modules/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'administration',
    title: 'Administration | Area49',
    loadChildren: () => import('src/app/modules/administration/administration.module').then(m => m.AdministrationModule),
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
